import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import CustomExpandIcon from '../CustomExpandIcon';
import { giftQuestion } from '../../../util/Constants';
import useClasses from '../../../hooks/legacy/useClasses';
import styles from './GiftAccordian.style';

function GiftAccordian() {
  const classes = useClasses(styles);

  return giftQuestion.map((giftIndevidualQuestions) => (
    <Accordion sx={{ boxShadow: 'none', p: 2 }}>
      <AccordionSummary
        expandIcon={<CustomExpandIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.questionHeading}>
          {giftIndevidualQuestions.question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography className={classes.answerHeading}>
          {giftIndevidualQuestions.answer}
        </Typography>
      </AccordionDetails>
    </Accordion>
  ));
}

export default GiftAccordian;
