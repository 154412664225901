import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  giftCartDetail: [],
  templatesData: {
    loading: false,
    data: [],
  },
  purchaseData: {
    loading: false,
    data: {},
  },
  checkoutData: {
    loading: false,
    data: {},
  },
};

const giftSlice = createSlice({
  name: 'gift',
  initialState: INITIAL_STATE,
  reducers: {
    setGiftCartDetail(state, { payload }) {
      return {
        ...state,
        giftCartDetail: payload,
      };
    },
    getTemplatesRequest(state) {
      return {
        ...state,
        templatesData: {
          loading: true,
          data: [],
        },
      };
    },
    getTemplatesSuccess(state, { payload }) {
      return {
        ...state,
        templatesData: {
          loading: false,
          data: payload,
        },
      };
    },
    getTemplatesFail(state) {
      return {
        ...state,
        templatesData: {
          loading: false,
          data: [],
        },
      };
    },
    purchaseGiftCertificatesRequest(state) {
      return {
        ...state,
        purchaseData: {
          loading: true,
          data: [],
        },
      };
    },
    purchaseGiftCertificatesSuccess(state, { payload }) {
      return {
        ...state,
        purchaseData: {
          loading: false,
          data: payload,
        },
      };
    },
    purchaseGiftCertificatesFail(state) {
      return {
        ...state,
        purchaseData: {
          loading: false,
          data: [],
        },
      };
    },
    resetPurchaseData(state) {
      return {
        ...state,
        purchaseData: {
          loading: false,
          data: [],
        },
      };
    },
    getCheckoutDataRequest(state) {
      return {
        ...state,
        checkoutData: {
          loading: true,
          data: [],
        },
      };
    },
    getCheckoutDataSuccess(state, { payload }) {
      return {
        ...state,
        checkoutData: {
          loading: false,
          data: payload,
        },
      };
    },
    getCheckoutDataFail(state) {
      return {
        ...state,
        checkoutData: {
          loading: false,
          data: [],
        },
      };
    },
  },
});

export const {
  setGiftCartDetail,
  getTemplatesRequest,
  getTemplatesSuccess,
  getTemplatesFail,
  purchaseGiftCertificatesRequest,
  purchaseGiftCertificatesSuccess,
  purchaseGiftCertificatesFail,
  resetPurchaseData,
  getCheckoutDataRequest,
  getCheckoutDataSuccess,
  getCheckoutDataFail,
} = giftSlice.actions;

export default giftSlice.reducer;
