import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';
import ReactGA from 'react-ga';
import 'leaflet/dist/leaflet.css';

import {
  doAppearIn,
  routeNames,
  IDLE_TIMEOUT_IN_SECONDS,
} from './util/Constants';
import { getConfig } from './AppConfig';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import Router from './components/navigation/Router';
import Loader from './components/layout/Loader/Loader';
import Notification from './components/layout/Notification';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import RestaurantNavbar from './components/layout/RestaurantNavbar';
import RestaurantFooter from './components/layout/RestaurantFooter';
import { isConsumerApp, isRestaurantApp } from './util/Helpers';
import ZohoSalesIQ from './components/navigation/ZohoSalesIQ';
import useDesktopCheck from './util/hooks/UseDesktopCheck';
import GiftCards from './components/page/GiftCards';

const config = getConfig();
const isRestaurant = isRestaurantApp();
const isConsumerWeb = isConsumerApp();

function App() {
  const theme = useTheme();
  const location = useLocation();
  const isDesktop = useDesktopCheck();

  const currentUrlPath = location.pathname;

  useEffect(() => {
    const gaTrackingId = config?.gaTrackingId || 'bogus_ga_tracking_id';
    ReactGA.initialize(gaTrackingId);
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useIdleTimer({
    timeout: IDLE_TIMEOUT_IN_SECONDS,
    crossTab: true,
    name: 'idle-timer',
    syncTimers: 0,
    onIdle: () => {
      window.location.reload();
    },
  });

  const { globalLoaderQueue } = useSelector(({ main }) => ({
    globalLoaderQueue: main.globalLoaderQueue,
  }));

  const inRestaurant = location.pathname.includes(
    routeNames.restaurant.replace(':id', '')
  );

  const inNotFound = location.pathname === routeNames.notfound;
  const isHomePage =
    location.pathname === routeNames.home ||
    location.pathname === routeNames.contact ||
    location.pathname === routeNames.aboutus;

  const inTrackOrder = location.pathname.includes(
    routeNames.trackOrder.replace(':id', '')
  );

  const inAccount = location.pathname.includes(
    routeNames.accountDetails.replace(':tab', '')
  );

  const inOrderSuccess = location.pathname.includes(
    routeNames.orderSuccess.replace(':id', '')
  );

  const inGiftPage = location.pathname === routeNames.giftCards;

  if (inNotFound) {
    return (
      <>
        <Loader show={globalLoaderQueue.length > 0} />
        <Box
          sx={{
            py: isHomePage ? 0 : 0,
            [theme.breakpoints.up('sm')]: {
              px: isHomePage ? 0 : 4,
            },
            [theme.breakpoints.up('lg')]: {
              px: isHomePage ? 0 : 12.5,
            },
            [theme.breakpoints.up('xxl')]: {
              px: isHomePage ? 0 : '20%',
            },
          }}
        >
          <Router />
        </Box>
      </>
    );
  }

  if (isRestaurant) {
    return (
      <>
        <Notification />
        <Loader show={globalLoaderQueue.length > 0} />
        {isHomePage ? <RestaurantNavbar /> : <Navbar />}
        <Box
          sx={{
            [theme.breakpoints.up('sm')]: {
              px: isHomePage ? 0 : 4,
            },
            [theme.breakpoints.up('lg')]: {
              px: isHomePage ? 0 : 12.5,
            },
            [theme.breakpoints.up('xxl')]: {
              px: isHomePage ? 0 : '20%',
            },
          }}
          data-test-id="main-cont"
        >
          <Router />
        </Box>
        <RestaurantFooter />
        {isDesktop && <ZohoSalesIQ />}
      </>
    );
  }
  if (isConsumerWeb) {
    let content;

    if (inGiftPage) {
      content = (
        <Box>
          <Navbar />
          <GiftCards />
          <Footer />
        </Box>
      );
    } else if (
      doAppearIn.includes(currentUrlPath) ||
      inRestaurant ||
      inTrackOrder ||
      inAccount ||
      inOrderSuccess
    ) {
      content = (
        <Box>
          <Navbar />
          <Box
            sx={{
              [theme.breakpoints.up('sm')]: {
                px: 4,
              },
              [theme.breakpoints.up('md')]: {
                px: 4,
              },
              [theme.breakpoints.up('lg')]: {
                px: 12.5,
              },
              [theme.breakpoints.up('xxl')]: {
                px: '20%',
              },
            }}
            data-test-id="main-cont"
          >
            <Router />
          </Box>
          <Footer />
          {isDesktop && <ZohoSalesIQ />}
        </Box>
      );
    } else {
      content = <Router />;
    }

    return (
      <>
        <Notification />
        <Loader show={globalLoaderQueue.length > 0} />
        {content}
      </>
    );
  }
  return null;
}
export default App;
