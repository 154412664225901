import * as React from 'react';
import { Box } from '@mui/system';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';

function CustomExpandIcon() {
  return (
    <Box
      sx={{
        '.Mui-expanded & > .collapsIconWrapper': {
          display: 'none',
        },
        '.expandIconWrapper': {
          display: 'none',
        },
        '.Mui-expanded & > .expandIconWrapper': {
          display: 'block',
        },
      }}
    >
      <div className="expandIconWrapper">
        <RemoveCircleOutlineRoundedIcon color="info" />
      </div>
      <div className="collapsIconWrapper">
        <AddCircleOutlineRoundedIcon color="info" />
      </div>
    </Box>
  );
}

export default CustomExpandIcon;
