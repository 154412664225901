import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { lighten, darken, transparentize } from 'polished';

const primaryColor = '#1D2939';
const secondaryColor = '#026AA2';
const successColor = '#49B782';
const themeConstants = {
  colors: {
    white201: '#FFF3F3',
    white202: '#F0FDF9',
    red110: '#F7A1A1',
    blue500: '#0086C9',
    fullRed: 'red',
    white11: '#E0F2FE',
    rgbWhite: 'rgb(198, 197, 202)',
    boxShadow10: 'rgba(16, 24, 40, 0.03)',
    boxShadow20: 'rgba(16, 24, 40, 0.05)',
    boxShadow100: 'rgba(0, 0, 0, 0.08)',
    blue310: '#7CD4FD',
    lightGrey10: 'lightgrey',
    mediumGray120: '#F2F4F7',
    blue210: '#107569',
    white200: '#FCFCFD',
    blue120: '#475467',
    white120: '#FFFAEB',
    red10: '#B54708',
    grey110: '#f5f5f5',
    blue10: '#065986',
    grey10: '#ced4da',
    white20: '#F0F0F0',
    white10: '#FEF3F2',
    pureWhite: 'white',
    red100: '#D92D20',
    blue110: '#101828',
    blue100: '#19D5C6',
    grey100: '#999',
    whiteGrey: '#eaecf0',
    dark10: '#344054',
    dark11: '#F9FAFB',
    dark12: '#888',
    mainText: '#212121',
    white12: '#F5FBFF',
    blueBackground: '#F0F9FF',
    semiDarkText: '#333333',
    placeholder: '#333',
    lightText: '#666666',
    lightText2: '#6c6c6c',
    contrastText: '#FFFFFF',
    contrastBackgroud: '#FFFFFF',
    linkText: '#3F9DD5',
    border: '#c3c3c3',
    secondaryBackground: '#F2F2F2',
    secondary60: '#0BA5EC',
    veryLightGray: '#f8f8f8',
    lightGray: '#DFDFDF',
    lightGray2: '#EDEEED',
    lightGray3: '#6C6C6C',
    mediumGray: '#D3D3D3',
    darkGray: '#363636',
    grayishGreen: '#E4F4EC',
    yellow: '#F3A12A',
    error: '#b71110',
    error700: '#B42318',
    indigo700: '#3538CD',
    indigo50: '#EEF4FF',
    warning50: '#FFA945',
    primary: {
      main: primaryColor,
      light: lighten(0.05, primaryColor),
      dark: darken(0.05, primaryColor),
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: secondaryColor,
      light: lighten(0.05, secondaryColor),
      dark: darken(0.05, secondaryColor),
      contrastText: '#FFFFFF',
    },
    success: {
      main: successColor,
      light: lighten(0.05, successColor),
      dark: darken(0.05, successColor),
      contrastText: '#FFFFFF',
    },
    orange: '#ff9502',
  },
  font: {
    size: {
      xs: 12,
      sm1: 15,
      sm: 14,
      md: 16,
      lg: 18,
      xl: 20,
      xl2: 24,
      xl3: 30,
      xl4: 36,
      xl5: 48,
      xl6: 60,
      xl7: 72,
    },
    lineHeight: {
      xs2: '16px',
      xs: '18px',
      sm: '20px',
      md: '24px',
      lg: '28px',
      xl: '30px',
      xl2: '32px',
      xl3: '38px',
      xl4: '44px',
      xl5: '60px',
      xl6: '72px',
      xl7: '90px',
    },
    weight: {
      light: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      extrabold: 800,
      boldest: 900,
    },
    family: {
      main: 'Mulish, sans-serif',
      secondary: '"Inter", sans-serif',
    },
  },
  breakpoint: {
    sm: 425,
    md: 781,
    lg: 1024,
  },
  boxShadow: '0px -3px 24px #00000029',
  transition: '0.15s',
  transitionDuration: 150,
  screenSize: {
    desktop: '@media (min-width:781px)',
    laptop: '@media (max-width:1200px)',
    tablet: '@media (max-width:730px)',
    mobile: '@media (max-width:780px)',
    middleWidth: '@media (max-width:850px)',
    smallerScreen: '@media (max-width:550px)',
    extraLargeScreen: '@media (min-width:1500px)',
    smallestScreen: '@media (max-width:600px)',
    extraSmallScreen: '@media (max-width:390px)',
  },
};

const defaultTheme = createTheme({
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },

    border: themeConstants.colors.border,
    primary: {
      main: primaryColor,
      contrastText: '#FFFFFF',
      dark: '#667085',
      light: themeConstants.colors.blueBackground,
    },
    secondary: {
      main: secondaryColor,
      contrastText: '#FFFFFF',
      text: '#000000',
      dark: '#D0D5DD',
    },
    grey: {
      main: '#f2f2f2',
    },
    grey400: {
      main: '#98A2B3',
      contrastText: '#98A2B3',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 2000,
    },
  },
  shape: {
    borderRadius: 8,
  },
  zIndex: {
    mobileStepper: 1,
    appBar: 125,
    drawer: 130,
    modal: 140,
    snackbar: 1,
    tooltip: 10,
  },
});

const matTheme = createTheme(defaultTheme, {
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: 'true',
      },
      styleOverrides: {
        root: {
          fontSize: themeConstants.font.size.md,
          textTransform: 'none',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          [defaultTheme.breakpoints.up('md')]: {
            borderRadius: 16,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          textTransform: 'capitalize',
          color: defaultTheme.palette.grey[700],
          fontSize: themeConstants.font.size.sm,
        },
      },
    },
    MuiCardContent: {
      defaultProps: {
        padding: 1,
      },
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingBottom: 16,
          },
          padding: 16,
          borderRadius: 8,
          [defaultTheme.breakpoints.up('md')]: {
            padding: 32,
            borderRadius: 16,
            '&:last-child': {
              paddingBottom: 32,
            },
          },
        },
      },
    },

    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          backgroundColor: defaultTheme.palette.grey[100],
        },
        grouped: {
          border: 'none',
          '&:hover': {
            borderRadius: 8,
          },
          '&.Mui-selected': {
            borderRadius: 8,
            backgroundColor: defaultTheme.palette.secondary.main,
            color: defaultTheme.palette.secondary.contrastText,
            '&:hover': {
              backgroundColor: defaultTheme.palette.secondary.light,
            },
          },
        },
      },
    },
  },

  typography: {
    htmlFontSize: themeConstants.font.size.md,
    fontFamily: themeConstants.font.family.main,
    fontSize: themeConstants.font.size.sm,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: themeConstants.font.family.main,
      fontSize: themeConstants.font.size.xl7, // 72px
    },
    h2: {
      fontFamily: themeConstants.font.family.main,
      fontSize: themeConstants.font.size.xl6, // 60px
      letterSpacing: '-2%',
      fontWeight: themeConstants.font.weight.bold,
    },
    h3: {
      fontFamily: themeConstants.font.family.main,
      fontSize: themeConstants.font.size.xl5, // 48px
    },
    h4: {
      fontFamily: themeConstants.font.family.main,
      fontWeight: themeConstants.font.weight.medium,
      fontSize: themeConstants.font.size.xl4, // 36px
    },
    h5: {
      fontFamily: themeConstants.font.family.main,
      fontSize: themeConstants.font.size.xl3, // 30px
    },
    h6: {
      fontFamily: themeConstants.font.family.main,
      fontSize: themeConstants.font.size.xl2, // 24px
    },
    subtitle1: {
      fontFamily: themeConstants.font.family.main,
      fontSize: themeConstants.font.size.xl, // 20px
    },
    subtitle2: {
      fontFamily: themeConstants.font.family.secondary,
      fontSize: themeConstants.font.size.lg, // 18px
    },
    body1: {
      fontFamily: themeConstants.font.family.secondary,
      fontSize: themeConstants.font.size.md, // 16px
    },
    body2: {
      fontFamily: themeConstants.font.family.secondary,
      fontSize: themeConstants.font.size.sm, // 14px
    },
    button: {
      fontFamily: themeConstants.font.family.secondary,
      fontSize: themeConstants.font.size.sm, // 14px
    },
    caption: {
      fontFamily: themeConstants.font.family.secondary,
      fontSize: themeConstants.font.size.xs, // 12px
      letterSpacing: '0.03333em',
    },
    grayText: {
      color: transparentize(0.4, themeConstants.colors.mainText),
      fontFamily: themeConstants.font.family.secondary,
      fontSize: themeConstants.font.size.md, // 16px
    },
  },
});
export const materialTheme = responsiveFontSizes(matTheme);
export default themeConstants;
