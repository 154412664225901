import theme from '../../layout/Theme';
import giftCardLeft from '../../../assets/images/giftCardLeft.png';
import giftCardMediumLeft from '../../../assets/images/giftCardMediumLeft.png';
import giftCardRight from '../../../assets/images/giftCardRight.png';
import giftCardMediumRight from '../../../assets/images/giftCardMediumRight.png';
import giftCardSmall from '../../../assets/images/giftCardSmall.png';

const styles = (materialTheme) => ({
  mainParentBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  upperBox: {
    background: materialTheme.palette.primary.main,
    color: materialTheme.palette.common.white,
  },
  giftCardBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  giftCardSubBox: {
    width: '50%',
    marginLeft: '96px',
    marginTop: '96px',
    [theme.screenSize.laptop]: {
      width: '80%',
      marginLeft: '50px',
    },
    [theme.screenSize.smallestScreen]: {
      width: '100%',
      marginLeft: '15px',
      marginRight: '10px',
    },
  },
  firstHeading: {
    fontWeight: 600,
    marginBottom: '24px',
    display: 'flex',
    width: '85%',
    justifyContent: 'center',
    [theme.screenSize.smallestScreen]: {
      justifyContent: 'start',
    },
  },
  secondHeading: {
    fontSize: theme.font.size.xl7,
    fontWeight: 700,
    marginBottom: '24px',
    [theme.screenSize.laptop]: {
      fontSize: theme.font.size.xl4,
    },
  },
  thirdHeading: {
    fontSize: theme.font.size.xl,
    fontWeight: 400,
    marginBottom: '40px',
    width: '82%',
    [theme.screenSize.laptop]: {
      width: '100%',
    },
  },
  purchaseButton: {
    display: 'flex',
    justifyContent: 'center',
    width: '85%',
    [theme.screenSize.laptop]: {
      width: '100%',
    },
  },
  innerButton: {
    background: 'white',
    marginBottom: '24px',
    padding: '16px',
    '&:hover': {
      backgroundColor: theme.colors.secondaryBackground,
    },
    [theme.screenSize.smallestScreen]: {
      width: '100%',
    },
  },
  lowerBox: {
    background: theme.colors.dark11,
    display: 'flex',
    justifyContent: 'center',
  },
  innerLowerBox: {
    width: '80%',
    marginTop: '80px',
    [theme.screenSize.smallestScreen]: {
      width: '100%',
      margin: '0px 8px',
    },
  },
  emailBox: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '24px',
    marginTop: '40px',
  },
  lowerBoxHeading: {
    margin: '0px 16px',
    [theme.screenSize.smallestScreen]: {
      margin: '0px 8px',
    },
  },
  questionMainHeading: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '16px',
    fontSize: theme.font.size.xl4,
    fontWeight: '700',
    [theme.screenSize.smallestScreen]: {
      fontSize: theme.font.size.xl3,
      padding: '0px 50px',
    },
  },
  questionSubHeading: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '40px',
    fontSize: theme.font.size.xl,
    fontWeight: '400',
    color: materialTheme.palette.primary.dark,
    [theme.screenSize.smallestScreen]: {
      fontSize: theme.font.size.md,
      padding: '0px 50px',
    },
  },
  questionBox: {
    width: '50%',
    [theme.screenSize.laptop]: {
      width: '86%',
    },
    [theme.screenSize.smallestScreen]: {
      width: '100%',
    },
  },
  questionParent: {
    display: 'flex',
    justifyContent: 'center',
  },
  emailInnerButton: {
    background: materialTheme.palette.primary.main,
    marginBottom: '24px',
    color: 'white',
    padding: '8px',
    '&:hover': {
      background: materialTheme.palette.primary.main,
    },
    width: '16%',
    [theme.screenSize.middleWidth]: {
      width: '100%',
    },
  },
  emailButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
    [theme.screenSize.laptop]: {
      width: '100%',
    },
  },
  stillQuestion: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: theme.font.size.xl,
    marginTop: '24px',
    marginBottom: '3px',
    [theme.screenSize.laptop]: {
      fontSize: theme.font.size.xl3,
    },
    [theme.screenSize.smallestScreen]: {
      fontSize: theme.font.size.xl3,
      marginBottom: '10px',
      marginLeft: '90px',
    },
  },
  lastBoxParent: {
    width: '75%',
    background: theme.colors.dark11,
    borderRadius: '16px',
    [theme.screenSize.laptop]: {
      width: '90%',
    },
  },
  subParentLastBox: {
    width: '50%',
    [theme.screenSize.laptop]: {
      width: '90%',
    },
  },
  cardLeft: {
    position: 'absolute',
    width: '556px',
    height: '459px',
    background: `url(${giftCardLeft})`,
    [theme.screenSize.laptop]: {
      position: 'absolute',
      width: '287px',
      height: '237px',
      background: `url(${giftCardMediumLeft})`,
    },
    [theme.screenSize.extraSmallScreen]: {
      display: 'none',
    },
  },
  cardRight: {
    position: 'absolute',
    width: '556px',
    height: '464px',
    right: '0px',
    background: `url(${giftCardRight})`,
    [theme.screenSize.laptop]: {
      position: 'absolute',
      right: '0px',
      width: '287px',
      height: '237px',
      background: `url(${giftCardMediumRight})`,
    },
    [theme.screenSize.extraSmallScreen]: {
      display: 'none',
    },
  },
  giftCardAnimationDesktop: {
    display: 'flex',
    justifyContent: 'center',
    height: '464px',
    [theme.screenSize.laptop]: {
      display: 'none',
    },
  },
  giftCardAnimationMedium: {
    display: 'none',
    [theme.screenSize.laptop]: {
      display: 'flex',
      justifyContent: 'center',
      height: '237px',
    },
  },
  giftCardSmall: {
    [theme.screenSize.extraSmallScreen]: {
      width: '100%',
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      height: '309px',
      background: `url(${giftCardSmall})`,
    },
  },
  giftCardParent: {
    [theme.screenSize.extraSmallScreen]: {
      display: 'flex',
      height: '310px',
      justifyContent: 'center',
    },
  },
});

export default styles;
