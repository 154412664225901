import theme from '../Theme';

const styles = (materialTheme) => ({
  buttonIconColor: {
    backgroundColor: theme.colors.white11,
  },
  personIconColor: {
    color: materialTheme.palette.secondary.main,
  },
  toolbarWhite: {
    backgroundColor: materialTheme.palette.common.white,
  },
  toolbarDark: {
    backgroundColor: theme.colors.primary.main,
    color: materialTheme.palette.common.white,
  },
  toolBarColorWhite: {
    color: materialTheme.palette.common.white,
  },
  toolBarColorBlack: {
    color: materialTheme.palette.common.black,
  },
});

export default styles;
