import { put, call, takeLeading } from 'redux-saga/effects';
import Bugsnag from '@bugsnag/js';

import {
  getGCTemplates,
  purchaseGiftCertificates,
  getCheckoutData,
} from '../../../../../util/api/GiftApiClient';
import Notification from '../../../../../components/layout/Notification';
import {
  getTemplatesRequest,
  getTemplatesSuccess,
  getTemplatesFail,
  purchaseGiftCertificatesRequest,
  purchaseGiftCertificatesSuccess,
  purchaseGiftCertificatesFail,
  getCheckoutDataRequest,
  getCheckoutDataSuccess,
  getCheckoutDataFail,
} from '../../../reducers/ducks/legacy/GiftDuck';

export function* getTemplatesSaga() {
  try {
    const templates = yield call(getGCTemplates);

    yield put(getTemplatesSuccess(templates));
  } catch (error) {
    Bugsnag.notify(error);
    yield put(getTemplatesFail());
    const message =
      `GC Templates: ${error.data?.message}` ||
      'Failed to get Gift Certificate templates';
    Notification.error(message, { toastId: 'gc-templates-error' });
  }
}

export function* purchaseGiftCertificatesSaga({ payload }) {
  try {
    const response = yield call(purchaseGiftCertificates, payload);

    yield put(purchaseGiftCertificatesSuccess(response));
  } catch (error) {
    Bugsnag.notify(error);
    yield put(purchaseGiftCertificatesFail());
    const message =
      `GC Buy: ${error.data?.message}` || 'Failed to buy Gift Certificates';
    Notification.error(message, { toastId: 'gc-buy-error' });
  }
}

export function* getCheckoutDataSaga({ payload }) {
  try {
    const response = yield call(getCheckoutData, payload);

    yield put(getCheckoutDataSuccess(response));
  } catch (error) {
    Bugsnag.notify(error);
    yield put(getCheckoutDataFail());
    const message =
      `GC Checkout Data: ${error.data?.message}` ||
      'Failed to get Gift Certificate checkout data';
    Notification.error(message, { toastId: 'gc-checkout-data-error' });
  }
}

export function* watchGiftSagas() {
  yield takeLeading(getTemplatesRequest.type, getTemplatesSaga);
  yield takeLeading(
    purchaseGiftCertificatesRequest.type,
    purchaseGiftCertificatesSaga
  );
  yield takeLeading(getCheckoutDataRequest.type, getCheckoutDataSaga);
}
