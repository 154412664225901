import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import GiftAccordian from '../../layout/GiftAccordian';
import emailImg from '../../../assets/images/emailImage.png';
import graph from '../../../assets/images/graph.png';
import send from '../../../assets/images/send.png';
import giftCardAnimation from '../../../assets/images/giftCardAnimation.jpg';
import giftAnimationMedium from '../../../assets/images/giftAnimationMedium.png';
import useClasses from '../../../hooks/legacy/useClasses';
import styles from './GiftCards.style';

function GiftCards() {
  const classes = useClasses(styles);

  return (
    <Box className={classes.mainParentBox}>
      <Box className={classes.upperBox}>
        <Box className={classes.giftCardBox}>
          <Box className={classes.giftCardSubBox}>
            <Box className={classes.firstHeading}>A gift that delivers</Box>
            <Box className={classes.secondHeading}>
              Shoreline Menus gift cards for food lovers
            </Box>
            <Box className={classes.thirdHeading}>
              Grab a customizable gift card for your loved ones and send them
              the gift of a good meal.
            </Box>
            <Box className={classes.purchaseButton}>
              <Button
                className={classes.innerButton}
                data-test-id="purchaseGift"
              >
                <NavLink to="/purchase-gift">Purchase a Gift Card</NavLink>
              </Button>
            </Box>
          </Box>
        </Box>
        <Box mt={4}>
          <Box className={classes.cardLeft} />
          <Box className={classes.cardRight} />
          <Box className={classes.giftCardSmall} />
        </Box>
        <Box className={classes.giftCardParent}>
          <Box className={classes.giftCardAnimationDesktop}>
            <img src={giftCardAnimation} alt="giftCardAnimation" width="90%" />
          </Box>
          <Box className={classes.giftCardAnimationMedium}>
            <img
              src={giftAnimationMedium}
              alt="giftCardAnimation"
              width="100%"
            />
          </Box>
        </Box>
      </Box>
      <Box className={classes.lowerBox}>
        <Box className={classes.innerLowerBox}>
          <Grid container spacing={2}>
            <Grid item lg={4} className={classes.lowerBoxHeading}>
              <Box className={classes.emailBox}>
                <img src={emailImg} alt="emailImg" />
              </Box>
              <Box className={classes.emailBox}>Select your gift card</Box>
              <Box>
                Curabitur quis semper dui. Nunc scelerisque neque in lacus
                facilisis, at pulvinar nisi dictum. Proin vel rhoncus ipsum. Nam
                vel orci nec massa ullamcorper dictum.
              </Box>
            </Grid>
            <Grid item lg={3} className={classes.lowerBoxHeading}>
              <Box className={classes.emailBox}>
                <img src={send} alt="send" />
              </Box>
              <Box className={classes.emailBox}>Send instantly</Box>
              <Box>
                Curabitur quis semper dui. Nunc scelerisque neque in lacus
                facilisis, at pulvinar nisi dictum. Proin vel rhoncus ipsum. Nam
                vel orci nec massa ullamcorper dictum.
              </Box>
            </Grid>
            <Grid item lg={4} className={classes.lowerBoxHeading}>
              <Box className={classes.emailBox}>
                <img src={graph} alt="graph" />
              </Box>
              <Box className={classes.emailBox}>Exchange for meals</Box>
              <Box>
                Curabitur quis semper dui. Nunc scelerisque neque in lacus
                facilisis, at pulvinar nisi dictum. Proin vel rhoncus ipsum. Nam
                vel orci nec massa ullamcorper dictum.
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box mt={20}>
        <Box className={classes.questionParent}>
          <Box className={classes.questionBox}>
            <Box>
              <Box className={classes.questionMainHeading}>
                Frequently asked questions
              </Box>
              <Box className={classes.questionSubHeading}>
                Everything you need to know about the product and billing.
              </Box>
              <Box>
                <GiftAccordian />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.questionParent} mt={5} mb={10}>
          <Box className={classes.lastBoxParent}>
            <Box className={classes.questionParent}>
              <Box className={classes.subParentLastBox}>
                <Typography className={classes.stillQuestion}>
                  Still have questions?
                </Typography>
                <Typography>
                  Can’t find the answer you’re looking for? Please chat to our
                  friendly team. Email us
                </Typography>
                <Box className={classes.emailButton}>
                  <Button
                    className={classes.emailInnerButton}
                    data-test-id="emailUs"
                  >
                    Email us
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default GiftCards;
