import { utcToZonedTime, formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';

import { addSeconds, addMinutes, startOfDay, addDays } from 'date-fns';
import { isNil } from 'lodash';
import { getConfig } from '../AppConfig';
import { serializeItem, isConsumerApp } from './Helpers';

const config = getConfig();

export const ORDER_TYPE_OPTIONS = [
  { label: 'Takeout', value: 'takeout', text: 'Pickup' },
  { label: 'Delivery', value: 'delivery', text: 'Delivery' },
];
export const WEEK_DAY_NAMES = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
export const DAY_FULL_NAMES = {
  TODAY: 'Today',
  TMR: 'Tomorrow',
  SUN: 'Sunday',
  MON: 'Monday',
  TUE: 'Tuesday',
  WED: 'Wednesday',
  THU: 'Thursday',
  FRI: 'Friday',
  SAT: 'Saturday',
};
export const ASAP_OPTION = {
  value: 'asap',
  type: 'asap',
  label: 'ASAP',
};

const calcZonedDate = (date, tz, fn, options = null) => {
  const inputZoned = utcToZonedTime(date, tz);
  const fnZoned = options ? fn(inputZoned, options) : fn(inputZoned);
  return zonedTimeToUtc(fnZoned, tz);
};

export const toBusinessTimezone = (date = new Date()) => {
  const inputZoned = utcToZonedTime(date, config.timezone);
  return zonedTimeToUtc(inputZoned, config.timezone);
};

export const timeNow = (add = 0) => {
  const today = toBusinessTimezone();
  return formatInTimeZone(addMinutes(today, add), config.timezone, 'p');
};

const getZonedStartOfDay = (date, timeZone) =>
  calcZonedDate(date, timeZone, startOfDay);

export const getDateOptions = () => {
  const now = new Date();

  const options = [];

  options.push({
    label: 'TODAY',
    fullName: DAY_FULL_NAMES.TODAY,
    value: now.getTime() / 1000,
    dayOfWeek: now.getDay(),
    dayOfMonth: parseInt(formatInTimeZone(now, config.timezone, 'd'), 10),
  });

  options.push({
    label: 'TMR',
    fullName: DAY_FULL_NAMES.TMR,
    value: addDays(now, 1).getTime() / 1000,
    dayOfWeek: addDays(now, 1).getDay(),
    dayOfMonth: parseInt(
      formatInTimeZone(addDays(now, 1), config.timezone, 'd'),
      10
    ),
  });

  while (options.length < 6) {
    const day = addDays(now, options.length);
    options.push({
      label: WEEK_DAY_NAMES[day.getDay()],
      fullName: DAY_FULL_NAMES[WEEK_DAY_NAMES[day.getDay()]],
      value: day.getTime() / 1000,
      dayOfWeek: day.getDay(),
      dayOfMonth: parseInt(formatInTimeZone(day, config.timezone, 'd'), 10),
    });
  }

  const result = options.map((i) => ({
    ...i,
    key: i.dayOfMonth,
    value: i.value,
  }));

  return result;
};

export const getIntervalWithSteps = (interval, step) => {
  const { start, end } = interval;

  const dates = [];
  let currentTimestamp = start.getTime();

  while (currentTimestamp < end.getTime()) {
    const intervalStart = new Date(currentTimestamp);
    dates.push(intervalStart);
    currentTimestamp += step * 1000;
  }

  return dates;
};

const getTimeOptionsByWindows = (date, windows, optionsConfig) => {
  const { businessTimezone, step } = optionsConfig;

  const result = [];

  for (let j = 0; j < windows.length; j++) {
    // TODO: should read from the array now
    const item = windows[j];
    if (isNil(item?.start) || isNil(item?.end)) {
      break;
    }

    const startDateObject = getZonedStartOfDay(date, config.timezone);
    const endDateObject = getZonedStartOfDay(date, config.timezone);

    const startTimeDate = addSeconds(startDateObject, item.start);
    const endTimeDate = addSeconds(endDateObject, item.end);

    const timeIntervals = { start: startTimeDate, end: endTimeDate };

    const intervals = getIntervalWithSteps(timeIntervals, step);

    intervals.forEach((interval) => {
      const intervalStartEpoch = interval.getTime() / 1000;
      const intervalEndEpoch = interval.getTime() / 1000 + step;

      const startTimeFormatted = formatInTimeZone(
        interval,
        config.timezone,
        'h:mma'
      );
      const endIntervalTimeDate = addSeconds(interval, step);
      const endTimeFormatted = formatInTimeZone(
        endIntervalTimeDate,
        config.timezone,
        'h:mma'
      );

      result.push({
        label: `${startTimeFormatted} - ${endTimeFormatted}`,
        value: {
          start: intervalStartEpoch,
          end: intervalEndEpoch,
          timezone: businessTimezone,
        },
        type: 'target',
      });
    });
  }

  return result;
};

export const getTimeOptions = ({ type, epoch = null, restaurantDetails }) => {
  let options = [];

  let dateValue = new Date();
  if (epoch) {
    dateValue = new Date(epoch * 1000);
  }

  const now = new Date();
  const todayStart = startOfDay(toBusinessTimezone(now));
  const dateStart = startOfDay(toBusinessTimezone(dateValue));

  const businessTimezone = config.timezone;
  const businessTakeoutDelay = isConsumerApp()
    ? config?.consumerSetup?.delays?.takeout
    : 0;
  const businessDeliveryDelay = isConsumerApp()
    ? config?.consumerSetup?.delays?.delivery
    : 0;
  const takeoutWindows = isConsumerApp()
    ? config?.consumerSetup?.windows?.takeout
    : [];
  const deliveryWindows = isConsumerApp()
    ? config?.consumerSetup?.windows?.delivery
    : [];
  const takeoutIntervalSize = isConsumerApp()
    ? config?.consumerSetup?.intervals?.takeout
    : 300;
  const deliveryIntervalSize = isConsumerApp()
    ? config?.consumerSetup?.intervals?.delivery
    : 900;

  const nowIndex = (todayStart.getDay() + 6) % 7;
  const dayIndex = (dateStart.getDay() + 6) % 7;

  let windows = null;
  let delay = null;
  let step = null;
  if (type === 'delivery') {
    step = deliveryIntervalSize;
  } else if (type === 'takeout') {
    step = takeoutIntervalSize;
  }

  if (restaurantDetails) {
    windows =
      type === 'takeout'
        ? restaurantDetails.schedules.takeout[dayIndex].openings.map(
            ({ open, close }) => ({ start: open, end: close })
          )
        : restaurantDetails.schedules.delivery[dayIndex].openings.map(
            ({ open, close }) => ({ start: open, end: close })
          );
    if (type === 'delivery') {
      delay = restaurantDetails.delays.delivery;
    } else if (type === 'takeout') {
      delay = restaurantDetails.delays.takeout;
    }
  } else if (type === 'delivery') {
    windows = deliveryWindows[dayIndex];
    delay = businessDeliveryDelay;
  } else if (type === 'takeout') {
    windows = takeoutWindows[dayIndex];
    delay = businessTakeoutDelay;
  }

  if (isNil(windows) || isNil(delay) || isNil(step)) {
    return [];
  }

  const isToday = nowIndex === dayIndex;

  const optionsConfig = {
    businessTimezone,
    step,
  };

  const limitEpochForAsap = now.getTime() / 1000 + delay;
  const timeOptions = getTimeOptionsByWindows(
    dateValue,
    windows,
    optionsConfig
  );
  let inInterval = !restaurantDetails;

  if (isToday) {
    for (let index = 0; index < timeOptions.length; index++) {
      const timeOption = timeOptions[index];
      if (limitEpochForAsap < timeOption.value.start) {
        options.push(timeOption);
      } else if (
        limitEpochForAsap >= timeOption.value.start &&
        limitEpochForAsap < timeOption.value.end
      ) {
        inInterval = true;
      }
    }
  } else {
    options.push(...timeOptions);
  }

  if (isToday) {
    if (inInterval) {
      options.shift();
      options = [
        {
          ...ASAP_OPTION,
          value: {
            start: Math.floor(limitEpochForAsap + 100).toString(),
            end: Math.floor(limitEpochForAsap + step + 100).toString(),
            timezone: businessTimezone,
          },
        },
        ...options,
      ];
    }
  }

  return options;
};

const getAllOptions = (restaurantDetails) => {
  const dateOptions = getDateOptions();

  const timeOptions = {};
  const distanceOptions = [
    { label: 'Any', value: 0 },
    { label: 'Less than 5mi', value: 5 },
    { label: 'Less than 10mi', value: 10 },
    { label: 'Less than 15mi', value: 15 },
  ];

  dateOptions.forEach((dateOption) => {
    timeOptions[dateOption.key] = {
      takeout: getTimeOptions({
        type: 'takeout',
        epoch: dateOption.value,
        restaurantDetails,
      }),
      delivery: getTimeOptions({
        type: 'delivery',
        epoch: dateOption.value,
        restaurantDetails,
      }),
    };
  });

  const result = {
    typeOptions: ORDER_TYPE_OPTIONS,
    dateOptions,
    timeOptions,
    distanceOptions,
  };

  return serializeItem(result);
};

export const findOption = (option, list = []) => {
  for (let i = 0; i < list.length; i += 1) {
    if (JSON.stringify(list[i]) === JSON.stringify(option)) {
      return list[i];
    }
  }

  return null;
};

export default getAllOptions;
