import theme from '../Theme';

const styles = (materialTheme) => ({
  questionHeading: {
    color: theme.colors.blue110,
    fontSIze: theme.font.size.lg,
  },
  answerHeading: {
    color: materialTheme.palette.primary.dark,
  },
});

export default styles;
