import axios from './Axios';
import { getConfig } from '../../AppConfig';

const path = '/gift-certificate';
const config = getConfig();

export const getGCTemplates = () =>
  axios.post(`${path}/templates/all`, {
    client: config.profileKey,
  });

export const purchaseGiftCertificates = (data) =>
  axios.post(`${path}/buy`, {
    ...data,
    client: config.profileKey,
  });

export const getCheckoutData = (checkoutCode) =>
  axios.get(`${path}/order/tracking/${checkoutCode}`);

export default {
  getGCTemplates,
  purchaseGiftCertificates,
  getCheckoutData,
};
